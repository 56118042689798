<template>
  <div class="loader">
    <img src="./assets/img/loader_mBank_icon.gif" alt="Obrazek informujący o przekierowaniu strony" />
  </div>
</template>

<script>
export default {
  name: 'Processing'
}
</script>

<style lang="scss" scoped>
.loader {
  img {
    max-width: 100%;
  }
}
</style>
